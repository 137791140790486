@charset "utf-8";

// Import a Google Font
//@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
// $green: #3f742f;
// $pink: #a7c5a5;
// $brown: #082709;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;

$bone: #FfFfFfff;
$eton-blue: #a3c9a8ff;
$green-sheen: #84b59fff;
$polished-pine: #488073ff;
$teal-blue: #50808eff;

$metallic-seaweed: #177e89ff;
$midnight-green-eagle-green: #084c61ff;
$cg-red: #db3a34ff;
$maximum-yellow-red: #ffc857ff;
$jet: #323031ff;

$forest-green-crayola: #4f9d69ff;
$blue-sapphire: #326273ff;
$wheat: #ead2acff;
$deep-taupe: #795663ff;
$english-violet: #513b56ff;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: #384558;
// $grey-light: $bone;
$primary: $polished-pine;
$link: $teal-blue;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
// $body-background-color: $grey-light;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
// @import "../node_modules/bulma/bulma.sass"
// @import "../node_modules/bulma/sass/utilities/_all.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/form/_all.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";

@import "../node_modules/bulma/bulma.sass";